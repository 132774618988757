import Cookies from "js-cookie";
import { initCaptcha, drawCodeImage, userInfo } from "@/api/index";
import { siteInfo, authorize, authorized } from "@/api/open";
import Footer from "@/views/main-components/footer";
import LangSwitch from "@/views/main-components/lang-switch";
import RectLoading from "@/views/my-components/xboot/rect-loading";
export default {
  components: {
    LangSwitch,
    Footer,
    RectLoading
  },
  data() {
    return {
      authLoading: false,
      title: "",
      msg: "",
      error: false,
      loadingCaptcha: true,
      captchaImg: "",
      loading: false,
      userInfo: {},
      site: {
        name: "",
        homeUri: "",
        logo: "",
        autoApprove: false
      },
      form: {
        username: "",
        password: "",
        code: "",
        client_id: "",
        redirect_uri: "",
        state: "",
        response_type: "",
        captchaId: this.captchaId
      },
      rules: {
        username: [{
          required: true,
          message: "请输入账号",
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: "请输入密码",
          trigger: "blur"
        }],
        code: [{
          required: true,
          message: "请输入验证码",
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    init() {
      let q = this.$route.query;
      if (!q.client_id) {
        this.title = "参数client_id非法";
        this.msg = "The parameter client_id is illegal.";
        this.error = true;
        return;
      }
      if (!q.redirect_uri) {
        this.title = "参数redirect_uri非法";
        this.msg = "The parameter redirect_uri is illegal.";
        this.error = true;
        return;
      }
      if (!q.state) {
        this.title = "参数state非法";
        this.msg = "The parameter state is illegal.";
        this.error = true;
        return;
      }
      this.form.client_id = q.client_id;
      this.form.redirect_uri = q.redirect_uri;
      this.form.state = q.state;
      this.form.responseType = q.responseType;
      this.getSiteInfo(this.form.client_id);
    },
    getSiteInfo(v) {
      siteInfo(v).then(res => {
        if (res.success) {
          this.site = res.result;
          this.isAuthed();
        } else {
          this.error = true;
          this.title = res.message;
          this.authLoading = false;
        }
      });
    },
    isAuthed() {
      // 判断是否认证过
      let userInfo = Cookies.get("userInfo");
      this.userInfo = userInfo;
      if (userInfo) {
        this.userInfo = JSON.parse(userInfo);
        // 自动授权
        if (this.site.autoApprove) {
          authorized(this.form).then(res => {
            if (res.success) {
              let url = res.result.redirect_uri + "?code=" + res.result.code + "&state=" + res.result.state;
              window.location.href = url;
            } else {
              this.authLoading = false;
            }
          });
        } else {
          this.authLoading = false;
        }
      } else {
        this.authLoading = false;
      }
    },
    confirm() {
      this.loading = true;
      authorized(this.form).then(res => {
        this.loading = false;
        if (res.success) {
          let url = res.result.redirect_uri + "?code=" + res.result.code + "&state=" + res.result.state;
          window.location.href = url;
        }
      });
    },
    getCaptchaImg() {
      this.loadingCaptcha = true;
      initCaptcha().then(res => {
        this.loadingCaptcha = false;
        if (res.success) {
          this.form.captchaId = res.result;
          this.captchaImg = drawCodeImage + res.result;
        }
      });
    },
    submit() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          authorize(this.form).then(res => {
            if (res.success) {
              // 存储accessToken
              this.setStore("accessToken", res.result.accessToken);
              let redictInfo = res.result;
              // 获取用户信息
              userInfo().then(res => {
                if (res.success) {
                  // 避免超过大小限制
                  delete res.result.permissions;
                  let roles = [];
                  res.result.roles.forEach(e => {
                    roles.push(e.name);
                  });
                  this.setStore("roles", roles);
                  Cookies.set("userInfo", JSON.stringify(res.result), {
                    expires: 7
                  });
                  this.setStore("userInfo", res.result);
                  this.$store.commit("setUserInfo", res.result);
                  // 跳转
                  let url = redictInfo.redirect_uri + "?code=" + redictInfo.code + "&state=" + redictInfo.state;
                  window.location.href = url;
                } else {
                  this.loading = false;
                  this.getCaptchaImg();
                }
              });
            } else {
              this.loading = false;
              this.getCaptchaImg();
            }
          });
        }
      });
    }
  },
  mounted() {
    this.init();
    this.getCaptchaImg();
  }
};